<template>
  <div>
    <Title>
      <span class="phase-title left">
        <span>{{ phase.phaseName }}（</span>
        <Money :money="phase.quota" />
        <span>万元）</span>
      </span>
    </Title>

    <div>
      <div class="list">
        <div class="other-list">
          <template v-if="phase.tec">
            <Item :item="phase.tec" :phase="phase" :phaseIndex="index" />
          </template>

          <major-group-component
            v-for="(el, idx) in phase.majorGroupList"
            :key="idx"
            :item="el"
            :index="idx"
            :majorGroupList="phase.majorGroupList"
            :phase="phase"
            :phaseIndex="index"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Item from "./phase-item.vue";
import Title from "./title";
import MajorGroupComponent from "./major-group.vue";
import { mapGetters, mapMutations, mapState } from "vuex";

const roleRatioList = [
  {
    value: "major_charger",
    ratio: 0.1,
  },
  {
    value: "design",
    ratio: 0.6,
  },
  {
    value: "recheck",
    ratio: 0.15,
  },
  {
    value: "audit",
    ratio: 0.1,
  },
  {
    value: "approval",
    ratio: 0.05,
  },
];

export default {
  components: {
    Item,
    Title,
    MajorGroupComponent,
  },

  props: {
    // 阶段在阶段列表中的索引
    index: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      visible: false, // 模态框显示隐藏
      selectedRowKeys: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    // 专业类型
    majorTypes() {
      return this.findDataDict("majorTypes").map((item) => ({
        ...item,
        key: item.value,
      }));
    },

    // 其他类型
    otherTypeList() {
      return this.findDataDict("sgphlx");
    },

    // 角色类型列表
    roleTypeList() {
      return this.findDataDict("prjAllocateRole");
    },

    ...mapState("majorAllocate", ["list"]), // 阶段列表

    // 阶段信息根据索引取出来
    phase() {
      return this.list[this.index];
    },

    // 剩余占比，总金额 - 前两个
    // surplusMoney() {
    //   let ratio = 0; // 总比例
    //   // this.phase.twoList.forEach((item) => {
    //   //   ratio += item.allocateQuota;
    //   // });
    //   return this.phase.quota * (100 - ratio) * 0.01;
    // },

    // 专业剩余占比
    // majorMoney() {
    //   // 有技术经济
    //   if (this.tecMoney) {
    //     return this.surplusMoney - this.tecMoney;
    //   } else {
    //     return this.surplusMoney;
    //   }
    // },
  },

  methods: {
    ...mapMutations("majorAllocate", ["setList"]), // 选择人员、修改比例，增加专业、增加其他都需要修改总的阶段列表

    // 增加专业
    ok() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.error("请选择专业类型！");
        return;
      }

      const params = [];
      this.selectedRowKeys.forEach((key) => {
        const obj = this.majorTypes.find((item) => item.key === key);
        params.push(obj);
      });

      if (params.length > 0) {
        const majorGroupList = this.phase.majorGroupList;

        this.phase.majorGroupList = [
          ...majorGroupList.map((item) => {
            return {
              ...item,
              list: [
                ...item.list,
                ...params
                  .filter((element) => {
                    return (
                      item.list.findIndex((obj) => obj.type === element.key) ===
                      -1
                    );
                  })
                  .map((element) => ({
                    type: element.key,
                    name: item.name,
                    allocateQuota: 10,
                    collapsed: false,

                    staffList: [
                      ...this.roleTypeList.map((type) => ({
                        role: type.value,

                        roleRatio:
                          roleRatioList.find(
                            (item) => item.value === type.value
                          ).ratio * 100,

                        allocateUnit: "%",
                        allocateRatio: 100,
                      })),
                    ],
                  })),
              ],
            };
          }),
        ];

        // 更新vuex，从而触发保存到 localStorage
        this.list[this.index] = this.phase;
        this.setList([...this.list]);
      }

      this.visible = false;
      this.selectedRowKeys = [];
    },

    deleteRatio(index) {
      this.$emit("deleteRatio", {
        phase: this.phase,
        ratioIndex: index,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.phase-title {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
}

.list {
  background-color: #f5f5f5;
  padding: 16px;
  margin-top: 16px;
}

.item {
  margin-bottom: 16px;
}
.item:last-child {
  margin-bottom: 0;
}

.header {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.ratio-title {
  display: flex;
  align-items: center;
}
.ratio-title > span {
  width: 120px;
  height: 32px;
  font-size: 13px;
  background: #1890ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.employee {
  margin-bottom: 12px;
}
.employee:last-child {
  margin-bottom: 0;
}

.other-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.other-list {
  padding: 0;
  margin-bottom: 12px;
}

.link-list {
  background-color: #f3f3f3;
  padding: 12px;
  margin-bottom: 16px;
  position: relative;
  min-height: 66px;

  .link {
    margin-bottom: 4px;
  }

  .control-bar {
    position: absolute;
    top: 0;
    right: 0;

    .control {
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .control:hover {
      color: #1890ff;
    }
  }
}

.small-title {
  font-weight: bold;
  margin-bottom: 16px;
}
</style>