<template>
  <div>
    <Pane :title="name" />

    <div class="container">
      <Detail />

      <div class="center">
        <a-space>
          <a-button type="primary" :disabled="okDisabled" :loading="loading" @click="ok">提交</a-button>
          <a-button @click="$close($route.path)">关闭</a-button>
        </a-space>
      </div>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import Detail from "./detail.vue";
import { getMoneyByMajorUser, setMoneyByMajorUser } from "@/api/project";
import { mapMutations, mapState } from "vuex";

import { check } from "./utils/tools";
import { division } from "./utils/validate";

export default {
  name: "majorAllocate",

  mixins: [watermark],

  components: {
    Detail,
  },

  data() {
    return {
      id: "",
      name: "",
      loading: false,
    };
  },

  activated() {
    const { query } = this.$route;
    const { id, name = "" } = query || {};

    if (id && this.id !== id) {
      this.id = id;
      this.name = name;
      this.getDetail();
    }
  },

  computed: {
    // 阶段列表、分包列表、是否从项目详情页面过来
    ...mapState("majorAllocate", ["list"]),

    // 确认按钮是否禁用
    okDisabled() {
      // 所有的阶段都是不可配置的，就禁用
      let count = 0;
      this.list.forEach((element) => {
        if (!element.configurable) {
          count++;
        }
      });
      return this.list.length === count;
    },
  },

  methods: {
    ...mapMutations("majorAllocate", ["setList"]),

    // 查询项目的分配情况
    getDetail() {
      getMoneyByMajorUser({
        id: this.id,
      }).then((res) => {
        if (Array.isArray(res)) {
          const newList = res.map((item) => {
            // 技术经济
            let tec = item.allocateList.find(
              (element) => element.type === "tech_dept"
            );

            if (tec) {
              tec = {
                ...tec,
                // allocateAmount:
                //   tec && tec.allocateAmount
                //     ? division(tec.allocateAmount)
                //     : undefined,
                // staffList: tec.allocateStaffList,

                staffList: tec?.allocateStaffList.map((el) => {
                  return {
                    ...el,
                    allocateRatio:
                      el.allocateUnit === "万" && el.allocateAmount
                        ? division(el.allocateAmount)
                        : el.allocateRatio,
                    allocateAmount: undefined,
                  };
                }),
              };
            }

            let majorGroupList = [];
            item.allocateList
              .filter((element) => element.majorGroup)
              .forEach((element) => {
                const index = majorGroupList.findIndex(
                  (major) => major.majorGroup === element.majorGroup
                );

                if (index > -1) {
                  majorGroupList[index].list.push({
                    ...element,

                    staffList: element.allocateStaffList.map((el) => {
                      return {
                        ...el,
                        allocateRatio:
                          el.allocateUnit === "万" && el.allocateAmount
                            ? division(el.allocateAmount)
                            : el.allocateRatio,
                        allocateAmount: undefined,
                      };
                    }),
                  });
                } else {
                  majorGroupList.push({
                    majorGroup: element.majorGroup,
                    majorGroupQuota: element.majorGroupQuota,
                    list: [
                      {
                        ...element,

                        staffList: element.allocateStaffList.map((el) => {
                          return {
                            ...el,
                            allocateRatio:
                              el.allocateUnit === "万" && el.allocateAmount
                                ? division(el.allocateAmount)
                                : el.allocateRatio,
                            allocateAmount: undefined,
                          };
                        }),
                      },
                    ],
                  });
                }
              });

            let configurable = true;
            let configMessage = "";

            // 合同审批状态为已通过，才可以配置
            if (item.phaseProgressStatus === "approved") {
              if (
                "reviewing approved".indexOf(item.staffConfigureStatus) === -1
              ) {
                configurable = true;
                configMessage = "";
              } else {
                configurable = false;
                configMessage = "产值分配审批中或审批通过，无法配置";
              }
            } else {
              configurable = false;
              configMessage = "阶段进度审批尚未通过，无法配置";
            }
            return {
              ...item,

              configurable,
              configMessage,

              majorGroupList,
              tec,
            };
          });

          this.setList(newList);
        }
      });
    },

    ok() {
      const message = check(this.list);
      if (message) {
        this.$message.error(message);
        return;
      }

      this.save();
    },

    save() {
      let arr = [];

      this.list
        .filter((item) => item.configurable)
        .forEach((item) => {
          // let attachments = item.attachments.map((item) => item.id);
          let allocateList = [];

          let majorList = [];
          item.majorGroupList.forEach((major) => {
            major.list.forEach((el) => {
              majorList.push({
                ...el,
                attachments: el.attachmentsList
                  ? el.attachmentsList.map((item) => item.id).join(",")
                  : null,
                majorGroup: major.majorGroup,
                majorGroupQuota: major.majorGroupQuota,

                allocateStaffList: el.staffList.map((staff) => {
                  return {
                    ...staff,
                    allocateRatio:
                      staff.allocateUnit === "万"
                        ? staff.allocateRatio * 10000
                        : staff.allocateRatio,
                  };
                }),
              });
            });
          });
          allocateList.push(...majorList);

          if (item.tec) {
            let tec = {
              ...item.tec,
              attachments: item.tec.attachmentsList
                ? item.tec.attachmentsList.map((item) => item.id).join(",")
                : null,
              allocateStaffList: item.tec.staffList?.map((staff) => {
                return {
                  ...staff,
                  allocateRatio:
                    staff.allocateUnit === "万"
                      ? staff.allocateRatio * 10000
                      : staff.allocateRatio,
                };
              }),
            };
            allocateList.push(tec);
          }

          arr.push({
            ...item,
            allocateList,
          });
        });

      this.loading = true;
      setMoneyByMajorUser({
        projectId: this.id,
        progressList: arr,
      })
        .then(() => {
          this.$close(this.$route.path);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  padding: 12px 12px 12px 0;
  background-color: #fff;
}

.center {
  padding: 48px 0;
}
</style>
